import React, { useEffect, useState } from 'react';
import ProfileDropdown from 'components/navbar/top/ProfileDropdown';
import { Nav } from 'react-bootstrap';
import ThemeControlDropdown from './ThemeControlDropdown';
import { formatDate } from 'helpers/date-format';

const TopNavRightSideNavItem = () => {
  const currentDate = new Date();
  const [time, setTime] = useState(new Date());

  useEffect(() => {
    const intervalID = setInterval(() => {
      setTime(new Date());
    }, 1000);

    return () => clearInterval(intervalID);
  }, []);

  return (
    <Nav
      navbar
      className="navbar-nav-icons ms-auto flex-row align-items-center"
      as="ul"
      style={{ marginTop: '4px' }}
    >
      <p style={{ marginBottom: 0, fontSize: '24px', color: '#fff' }}>
        {formatDate(currentDate)}, {time.toLocaleTimeString('en-US')}
      </p>
      <ThemeControlDropdown />
      {/* <CartNotification />
      <NotificationDropdown />
      <NineDotMenu /> */}
      <ProfileDropdown />
    </Nav>
  );
};

export default TopNavRightSideNavItem;
