import React, { Fragment, useEffect, useState } from 'react';
import { Col, ListGroup, Row } from 'react-bootstrap';
import GaugeComponent from 'react-gauge-component';
// import { useNavigate } from 'react-router-dom';
import AINCMAP from 'assets/img/doe-network-map.jpg';
import FalconLightBox from 'components/common/FalconLightBox';
import axiosInstance from 'services/axiosInstance';

const DOENetworkMap = () => {
  //   const navigate = useNavigate();

  const [data, setData] = useState([]);

  useEffect(() => {
    axiosInstance.get('/cloud-work-data/DOE Network').then(res => {
      setData(res.data.result);
    });
  }, []);

  useEffect(() => {
    console.log(data);
    const myFunction = () => {
      if (data && data.length > 0) {
        const _data = data.map(da => {
          return {
            ...da,
            value:
              da.type === 'performance' || da.type === 'throughput'
                ? Math.floor(Math.random() * (+da.value + 10 - +da.value + 1)) +
                  +da.value
                : // : da.type === 'tatency'
                  // ? Math.floor(Math.random() * +da.value) + 2
                  +da.value
          };
        });

        // console.log(_data);
        setData(_data);
      }
    };

    const intervalId = setInterval(myFunction, 8000);

    return () => clearInterval(intervalId);
  }, [data]);

  const kbitsToMbits = value => {
    if (value >= 1000) {
      value = value / 1000;
      if (Number.isInteger(value)) {
        return value.toFixed(0) + ' GBs';
      } else {
        return value.toFixed(1) + ' GBs';
      }
    } else {
      return value.toFixed(0) + ' GBs';
    }
  };

  const formatPerf = value => {
    // value.toString().replace(/0/g, '');
    // if (value >= 100) {
    //   value = value / 100;
    //   if (Number.isInteger(value)) {
    //     return value.toFixed(0) + ' Gbs';
    //   } else {
    //     return value.toFixed(1) + ' Gbs';
    //   }
    // } else {
    return value.toFixed(0) + ' Gbs';
    //}
  };

  const latencyMeasurement = value => {
    return value + ' ms';
  };

  const formatAvailability = value => {
    var number = value;
    var formattedNumber = parseFloat(number.toFixed(3)) + '%';
    return formattedNumber;
  };

  return (
    <Fragment>
      <Row className="g-3 mb-3 mt-4">
        <h5>Performance</h5>
        {data &&
          data.length > 0 &&
          data.map(v => {
            if (v.type === 'performance') {
              return (
                <Col md={3} key={v.cloudId}>
                  <h6 className="mb-0 mt-2 text-center">{v.provider_name}</h6>
                  <GaugeComponent
                    type="semicircle"
                    arc={{
                      nbSubArcs: 150,
                      colorArray: ['#5BE12C', '#F5CD19', '#EA4228'],
                      width: 0.3,
                      padding: 0.003
                    }}
                    labels={{
                      valueLabel: {
                        fontSize: 40,
                        formatTextValue: formatPerf
                      },
                      tickLabels: {
                        type: 'outer',
                        ticks: [
                          { value: 100 },
                          { value: 200 },
                          { value: 300 },
                          { value: 400 },
                          { value: 500 },
                          { value: 600 },
                          { value: 700 },
                          { value: 800 }
                        ],
                        valueConfig: {
                          formatTextValue: formatPerf
                        }
                      }
                    }}
                    value={+v.value}
                    maxValue={800}
                    pointer={{ type: 'arrow', elastic: true }}
                  />
                </Col>
              );
            }
          })}
      </Row>
      <Row className="g-3 mb-3 mt-4">
        <h5>Throughput</h5>
        {data &&
          data.length > 0 &&
          data.map(v => {
            if (v.type === 'throughput') {
              return (
                <Col md={3} key={v.cloudId}>
                  <h6 className="mb-0 mt-2 text-center">{v.provider_name}</h6>
                  <GaugeComponent
                    type="semicircle"
                    arc={{
                      nbSubArcs: 150,
                      colorArray: ['#5BE12C', '#F5CD19', '#EA4228'],
                      width: 0.3,
                      padding: 0.003
                    }}
                    labels={{
                      valueLabel: {
                        fontSize: 32,
                        formatTextValue: kbitsToMbits,
                        style: {
                          fill: '#fff'
                        }
                      },
                      tickLabels: {
                        type: 'outer',
                        ticks: [
                          { value: 10 },
                          { value: 20 },
                          { value: 40 },
                          { value: 60 },
                          { value: 80 },
                          { value: 100 },
                          { value: 120 },
                          { value: 150 },
                          { value: 200 }
                        ],
                        valueConfig: {
                          formatTextValue: kbitsToMbits
                        }
                      }
                    }}
                    value={+v.value}
                    maxValue={200}
                    // pointer={{
                    //   elastic: true,
                    //   animationDelay: 0
                    // }}
                    pointer={{ type: 'arrow', elastic: true }}
                  />
                </Col>
              );
            }
          })}
      </Row>
      <Row className="g-3 mb-3 mt-4">
        <h5>Latency</h5>
        {data &&
          data.length > 0 &&
          data.map(v => {
            if (v.type === 'latency') {
              return (
                <Col md={3} key={v.cloudId}>
                  <h6 className="mb-3 mt-2 text-center">{v.provider_name}</h6>
                  <GaugeComponent
                    type="semicircle"
                    arc={{
                      nbSubArcs: 150,
                      colorArray: ['#5BE12C', '#F5CD19', '#EA4228'],
                      width: 0.3,
                      padding: 0.003
                    }}
                    labels={{
                      valueLabel: {
                        fontSize: 40,
                        formatTextValue: latencyMeasurement
                      },
                      tickLabels: {
                        type: 'outer',
                        ticks: [
                          { value: 10 },
                          { value: 20 },
                          { value: 40 },
                          { value: 60 },
                          { value: 80 },
                          { value: 100 },
                          { value: 120 },
                          { value: 150 },
                          { value: 200 }
                        ],
                        valueConfig: {
                          formatTextValue: latencyMeasurement
                        }
                      }
                    }}
                    value={+v.value}
                    maxValue={200}
                    pointer={{ type: 'arrow', elastic: true }}
                  />
                </Col>
              );
            }
          })}
      </Row>
      <Row className="g-3 mb-3 mt-4">
        <h5>Availability</h5>
        {data &&
          data.length > 0 &&
          data.map(v => {
            if (v.type === 'availability') {
              return (
                <Col md={3} key={v.cloudId}>
                  <h6 className="mb-0 mt-2 text-center">AWS</h6>
                  <GaugeComponent
                    arc={{
                      nbSubArcs: 150,
                      colorArray: ['#EA4228', '#F5CD19', '#5BE12C'],
                      width: 0.3,
                      padding: 0.003,
                      subArcs: [{ limit: 70 }, {}, {}, {}, {}, {}, {}]
                    }}
                    labels={{
                      valueLabel: {
                        fontSize: 40,
                        formatTextValue: formatAvailability
                      },
                      tickLabels: {
                        type: 'outer',
                        ticks: [
                          { value: 70 },
                          { value: 80 },
                          { value: 95 },
                          { value: 96 },
                          { value: 97 },
                          { value: 98 },
                          { value: 99 },
                          { value: 100 }
                        ],
                        valueConfig: {
                          formatTextValue: formatAvailability
                        }
                      }
                    }}
                    value={99.99}
                    minValue={70}
                    maxValue={100}
                  />
                </Col>
              );
            }
          })}
      </Row>
      <Row className="g-3 mb-3 mt-4">
        <Col md={6}>
          {/* <GoogleMap
            initialCenter={{
              lat: 48.8583736,
              lng: 2.2922926
            }}
            mapStyle="Default"
            darkStyle="Cobalt"
            className="vh-50"
          >
            <h5 className="text-700">Eiffel Tower</h5>
            <p className="fs-10 mb-0">
              Gustave Eiffel's iconic, wrought-iron 1889 tower,
              <br /> with steps and elevators to observation decks.
            </p>
          </GoogleMap> */}
          <FalconLightBox image={AINCMAP}>
            <img
              src={AINCMAP}
              className="img-fluid rounded mb-2 cursor-pointer "
            />
          </FalconLightBox>
        </Col>
        <Col md={6}>
          <h4>Customer</h4>
          <ListGroup>
            <ListGroup.Item style={{ fontSize: '14px' }}>
              DOE Network Map
            </ListGroup.Item>
          </ListGroup>

          {/* <ListGroup style={{ marginTop: '24px' }}>
            <ListGroup.Item style={{ cursor: 'pointer' }} onClick={goToTicket}>
              7e37e3ehu3
            </ListGroup.Item>
            <ListGroup.Item style={{ cursor: 'pointer' }} onClick={goToTicket}>
              d2uh383dh2
            </ListGroup.Item>
            <ListGroup.Item style={{ cursor: 'pointer' }} onClick={goToTicket}>
              7e837eu333
            </ListGroup.Item>
            <ListGroup.Item style={{ cursor: 'pointer' }} onClick={goToTicket}>
              h3ddh37d33
            </ListGroup.Item>
          </ListGroup> */}
        </Col>
      </Row>
    </Fragment>
  );
};

export default DOENetworkMap;
