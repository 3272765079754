import React, { Fragment, useEffect, useState } from 'react';
import { Col, ListGroup, Row } from 'react-bootstrap';
import GaugeComponent from 'react-gauge-component';
// import { useNavigate } from 'react-router-dom';
import DELLAincMapImg from 'assets/img/dell-ainc-network-map.jpg';
import FalconLightBox from 'components/common/FalconLightBox';
import axiosInstance from 'services/axiosInstance';

const AdminMultiClouds = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    axiosInstance.get('/cloud-work-data').then(res => {
      setData(res.data.result);
    });
  }, []);

  useEffect(() => {
    const myFunction = () => {
      if (data && data.length > 0) {
        const _data = data.map(da => {
          return {
            ...da,
            providers: da.providers.map(provider => {
              return {
                ...provider,
                value:
                  da.type === 'performance' || da.type === 'throughput'
                    ? Math.floor(
                        Math.random() *
                          (+provider.value + 10 - +provider.value + 1)
                      ) + +provider.value
                    : // : da.type === 'tatency'
                      // ? Math.floor(Math.random() * +provider.value) + 2
                      +provider.value
              };
            })
          };
        });
        // console.log(_data);
        setData(_data);
      }
    };

    const intervalId = setInterval(myFunction, 8000);

    return () => clearInterval(intervalId);
  }, [data]);

  // useEffect(() => {
  //   const myFunction = () => {
  //     if (data && data.length > 0) {
  //       const _data = data.map(da => {
  //         return {
  //           ...da,
  //           value:
  //             da.type === 'Throughput'
  //               ? Math.floor(Math.random() * +da.value) + 2
  //               : da.value
  //         };
  //       });
  //       // console.log(_data);
  //       setData(_data);
  //     }
  //   };

  //   const intervalId = setInterval(myFunction, 3000);

  //   return () => clearInterval(intervalId);
  // }, [data]);

  // const kbitsToMbits = value => {
  //   if (value >= 1000) {
  //     value = value / 1000;
  //     if (Number.isInteger(value)) {
  //       return value.toFixed(0) + ' GBs';
  //     } else {
  //       return value.toFixed(1) + ' GBs';
  //     }
  //   } else {
  //     return value.toFixed(0) + ' GBs';
  //   }
  // };

  const formatPerf = value => {
    // value.toString().replace(/0/g, '');
    // if (value >= 100) {
    //   value = value / 100;
    //   if (Number.isInteger(value)) {
    //     return value.toFixed(0) + ' Gbs';
    //   } else {
    //     return value.toFixed(1) + ' Gbs';
    //   }
    // } else {
    return value.toFixed(0) + ' Gbs';
    // }
  };

  const formatThroughput = value => {
    return value.toFixed(0) + ' GBs';
  };

  const latencyMeasurement = value => {
    return value + ' ms';
  };

  // const latencyMeasurement = value => {
  //   return value + ' ms';
  // };

  const formatAvailability = value => {
    console.log(value);
    var number = value;
    var formattedNumber = parseFloat(number.toFixed(3)) + '%';
    return formattedNumber;
  };

  // const goToTicket = () => {
  //   navigate('/app/tickets');
  // };

  return (
    <Fragment>
      <h4 style={{ marginTop: '8px' }}>DELL AINC Network</h4>
      <Row className="g-3 mb-3 mt-4">
        <h5>Performance</h5>
        {data &&
          data.length > 0 &&
          data.map(v => {
            if (v.type === 'performance') {
              return (
                <>
                  {v.providers.map(provider => {
                    return (
                      <Col md={3} key={provider.providerName}>
                        <h6 className="mb-3 mt-2 text-center">
                          {provider.providerName}
                        </h6>
                        <GaugeComponent
                          type="semicircle"
                          arc={{
                            nbSubArcs: 150,
                            colorArray: ['#5BE12C', '#F5CD19', '#EA4228'],
                            width: 0.3,
                            padding: 0.003
                          }}
                          labels={{
                            valueLabel: {
                              fontSize: 40,
                              formatTextValue: formatPerf
                            },
                            tickLabels: {
                              type: 'outer',
                              ticks: [
                                { value: 100 },
                                { value: 200 },
                                { value: 400 },
                                { value: 600 },
                                { value: 800 },
                                { value: 1000 },
                                { value: 1200 },
                                { value: 1400 },
                                { value: 1600 }
                              ],
                              valueConfig: {
                                formatTextValue: formatPerf
                              }
                            }
                          }}
                          value={+provider.value}
                          maxValue={1600}
                          pointer={{ type: 'arrow', elastic: true }}
                        />
                      </Col>
                    );
                  })}
                </>
              );
            }
          })}

        {/* <Col md={3}>
          <h6 className="mb-0 mt-2 text-center">Azure</h6>
          <GaugeComponent
            arc={{
              nbSubArcs: 150,
              colorArray: ['#5BE12C', '#F5CD19', '#EA4228'],
              width: 0.3,
              padding: 0.003
            }}
            labels={{
              valueLabel: {
                fontSize: 40,
                formatTextValue: formatPerf
              },
              tickLabels: {
                type: 'outer',
                ticks: [
                  { value: 10 },
                  { value: 20 },
                  { value: 40 },
                  { value: 60 },
                  { value: 80 },
                  { value: 100 },
                  { value: 120 },
                  { value: 150 },
                  { value: 200 }
                ],
                valueConfig: {
                  formatTextValue: formatPerf
                }
              }
            }}
            value={40}
            maxValue={200}
          />
        </Col> */}

        {/*<Col md={3}>
          <h6 className="mb-0 mt-2 text-center">Google</h6>
          <GaugeComponent
            arc={{
              nbSubArcs: 150,
              colorArray: ['#5BE12C', '#F5CD19', '#EA4228'],
              width: 0.3,
              padding: 0.003
            }}
            labels={{
              valueLabel: {
                fontSize: 40,
                formatTextValue: formatPerf
              },
              tickLabels: {
                type: 'outer',
                ticks: [
                  { value: 10 },
                  { value: 20 },
                  { value: 40 },
                  { value: 60 },
                  { value: 80 },
                  { value: 100 },
                  { value: 120 },
                  { value: 150 },
                  { value: 200 }
                ],
                valueConfig: {
                  formatTextValue: formatPerf
                }
              }
            }}
            value={72}
            maxValue={200}
          />
        </Col>

        <Col md={3}>
          <h6 className="mb-0 mt-2 text-center">Oracle</h6>
          <GaugeComponent
            arc={{
              nbSubArcs: 150,
              colorArray: ['#5BE12C', '#F5CD19', '#EA4228'],
              width: 0.3,
              padding: 0.003
            }}
            labels={{
              valueLabel: {
                fontSize: 40,
                formatTextValue: formatPerf
              },
              tickLabels: {
                type: 'outer',
                ticks: [
                  { value: 10 },
                  { value: 20 },
                  { value: 40 },
                  { value: 60 },
                  { value: 80 },
                  { value: 100 },
                  { value: 120 },
                  { value: 150 },
                  { value: 200 }
                ],
                valueConfig: {
                  formatTextValue: formatPerf
                }
              }
            }}
            value={52}
            maxValue={200}
          />
        </Col> */}
      </Row>
      <Row className="g-3 mb-3 mt-4">
        <h5>Throughput</h5>
        {data &&
          data.length > 0 &&
          data.map(v => {
            if (v.type === 'throughput') {
              return (
                <>
                  {v.providers.map(provider => {
                    return (
                      <Col md={3} key={provider.providerName}>
                        <h6 className="mb-3 mt-2 text-center">
                          {provider.providerName}
                        </h6>
                        <GaugeComponent
                          type="semicircle"
                          arc={{
                            nbSubArcs: 150,
                            colorArray: ['#5BE12C', '#F5CD19', '#EA4228'],
                            width: 0.3,
                            padding: 0.003
                          }}
                          labels={{
                            valueLabel: {
                              fontSize: 40,
                              formatTextValue: formatThroughput
                            },
                            tickLabels: {
                              type: 'outer',
                              ticks: [
                                { value: 100 },
                                { value: 200 },
                                { value: 300 },
                                { value: 400 },
                                { value: 500 },
                                { value: 600 },
                                { value: 700 },
                                { value: 800 },
                                { value: 900 },
                                { value: 1000 },
                                { value: 1100 },
                                { value: 1200 }
                              ],
                              valueConfig: {
                                formatTextValue: formatThroughput
                              }
                            }
                          }}
                          value={+provider.value}
                          maxValue={1200}
                          pointer={{ type: 'arrow', elastic: true }}
                        />
                      </Col>
                    );
                  })}
                </>
              );
            }
          })}
      </Row>
      <Row className="g-3 mb-3 mt-4">
        <h5>Latency (avg)</h5>
        {data &&
          data.length > 0 &&
          data.map(v => {
            if (v.type === 'latency') {
              return (
                <>
                  {v.providers.map(provider => {
                    return (
                      <Col md={3} key={provider.providerName}>
                        <h6 className="mb-3 mt-2 text-center">
                          {provider.providerName}
                        </h6>
                        <GaugeComponent
                          type="semicircle"
                          arc={{
                            nbSubArcs: 150,
                            colorArray: ['#5BE12C', '#F5CD19', '#EA4228'],
                            width: 0.3,
                            padding: 0.003
                          }}
                          labels={{
                            valueLabel: {
                              fontSize: 40,
                              formatTextValue: latencyMeasurement
                            },
                            tickLabels: {
                              type: 'outer',
                              ticks: [
                                { value: 10 },
                                { value: 20 },
                                { value: 40 },
                                { value: 60 },
                                { value: 80 },
                                { value: 100 },
                                { value: 120 },
                                { value: 150 },
                                { value: 200 }
                              ],
                              valueConfig: {
                                formatTextValue: latencyMeasurement
                              }
                            }
                          }}
                          value={+provider.value}
                          maxValue={200}
                          pointer={{ type: 'arrow', elastic: true }}
                        />
                      </Col>
                    );
                  })}
                </>
              );
            }
          })}

        {/* <Col md={3}>
          <h6 className="mb-0 mt-2 text-center">Azure</h6>
          <GaugeComponent
            arc={{
              nbSubArcs: 150,
              colorArray: ['#5BE12C', '#F5CD19', '#EA4228'],
              width: 0.3,
              padding: 0.003
            }}
            labels={{
              valueLabel: {
                fontSize: 40,
                formatTextValue: latencyMeasurement
              },
              tickLabels: {
                type: 'outer',
                ticks: [
                  { value: 10 },
                  { value: 20 },
                  { value: 40 },
                  { value: 60 },
                  { value: 80 },
                  { value: 100 },
                  { value: 120 },
                  { value: 150 },
                  { value: 200 }
                ],
                valueConfig: {
                  formatTextValue: latencyMeasurement
                }
              }
            }}
            value={5}
            maxValue={200}
          />
        </Col>

        <Col md={3}>
          <h6 className="mb-0 mt-2 text-center">Google</h6>
          <GaugeComponent
            arc={{
              nbSubArcs: 150,
              colorArray: ['#5BE12C', '#F5CD19', '#EA4228'],
              width: 0.3,
              padding: 0.003
            }}
            labels={{
              valueLabel: {
                fontSize: 40,
                formatTextValue: latencyMeasurement
              },
              tickLabels: {
                type: 'outer',
                ticks: [
                  { value: 10 },
                  { value: 20 },
                  { value: 40 },
                  { value: 60 },
                  { value: 80 },
                  { value: 100 },
                  { value: 120 },
                  { value: 150 },
                  { value: 200 }
                ],
                valueConfig: {
                  formatTextValue: latencyMeasurement
                }
              }
            }}
            value={6}
            maxValue={200}
          />
        </Col>

        <Col md={3}>
          <h6 className="mb-0 mt-2 text-center">Oracle</h6>
          <GaugeComponent
            arc={{
              nbSubArcs: 150,
              colorArray: ['#5BE12C', '#F5CD19', '#EA4228'],
              width: 0.3,
              padding: 0.003
            }}
            labels={{
              valueLabel: {
                fontSize: 40,
                formatTextValue: latencyMeasurement
              },
              tickLabels: {
                type: 'outer',
                ticks: [
                  { value: 10 },
                  { value: 20 },
                  { value: 40 },
                  { value: 60 },
                  { value: 80 },
                  { value: 100 },
                  { value: 120 },
                  { value: 150 },
                  { value: 200 }
                ],
                valueConfig: {
                  formatTextValue: latencyMeasurement
                }
              }
            }}
            value={4}
            maxValue={200}
          />
        </Col> */}
      </Row>
      <Row className="g-3 mb-3 mt-4">
        <h5>Availability</h5>
        <Col md={3}>
          <h6 className="mb-0 mt-2 text-center">AWS</h6>
          <GaugeComponent
            arc={{
              nbSubArcs: 150,
              colorArray: ['#EA4228', '#F5CD19', '#5BE12C'],
              width: 0.3,
              padding: 0.003,
              subArcs: [{ limit: 70 }, {}, {}, {}, {}, {}, {}]
            }}
            labels={{
              valueLabel: {
                fontSize: 40,
                formatTextValue: formatAvailability
              },
              tickLabels: {
                type: 'outer',
                ticks: [
                  { value: 70 },
                  { value: 80 },
                  { value: 95 },
                  { value: 96 },
                  { value: 97 },
                  { value: 98 },
                  { value: 99 },
                  { value: 100 }
                ],
                valueConfig: {
                  formatTextValue: formatAvailability
                }
              }
            }}
            value={99.99}
            minValue={70}
            maxValue={100}
          />
        </Col>

        <Col md={3}>
          <h6 className="mb-0 mt-2 text-center">Azure</h6>
          <GaugeComponent
            arc={{
              nbSubArcs: 150,
              colorArray: ['#EA4228', '#F5CD19', '#5BE12C'],
              width: 0.3,
              padding: 0.003,
              subArcs: [{ limit: 70 }, {}, {}, {}, {}, {}, {}]
            }}
            labels={{
              valueLabel: {
                fontSize: 40,
                formatTextValue: formatAvailability
              },
              tickLabels: {
                type: 'outer',
                ticks: [
                  { value: 70 },
                  { value: 80 },
                  { value: 95 },
                  { value: 96 },
                  { value: 97 },
                  { value: 98 },
                  { value: 99 },
                  { value: 100 }
                ],
                valueConfig: {
                  formatTextValue: formatAvailability
                }
              }
            }}
            value={99.99}
            minValue={70}
            maxValue={100}
          />
        </Col>

        <Col md={3}>
          <h6 className="mb-0 mt-2 text-center">Google</h6>
          <GaugeComponent
            arc={{
              nbSubArcs: 150,
              colorArray: ['#EA4228', '#F5CD19', '#5BE12C'],
              width: 0.3,
              padding: 0.003,
              subArcs: [{ limit: 70 }, {}, {}, {}, {}, {}, {}]
            }}
            labels={{
              valueLabel: {
                fontSize: 40,
                formatTextValue: formatAvailability
              },
              tickLabels: {
                type: 'outer',
                ticks: [
                  { value: 70 },
                  { value: 80 },
                  { value: 95 },
                  { value: 96 },
                  { value: 97 },
                  { value: 98 },
                  { value: 99 },
                  { value: 100 }
                ],
                valueConfig: {
                  formatTextValue: formatAvailability
                }
              }
            }}
            value={99.99}
            minValue={70}
            maxValue={100}
          />
        </Col>

        <Col md={3}>
          <h6 className="mb-0 mt-2 text-center">Oracle</h6>
          <GaugeComponent
            arc={{
              nbSubArcs: 150,
              colorArray: ['#EA4228', '#F5CD19', '#5BE12C'],
              width: 0.3,
              padding: 0.003,
              subArcs: [{ limit: 70 }, {}, {}, {}, {}, {}, {}]
            }}
            labels={{
              valueLabel: {
                fontSize: 40,
                formatTextValue: formatAvailability
              },
              tickLabels: {
                type: 'outer',
                ticks: [
                  { value: 70 },
                  { value: 80 },
                  { value: 95 },
                  { value: 96 },
                  { value: 97 },
                  { value: 98 },
                  { value: 99 },
                  { value: 100 }
                ],
                valueConfig: {
                  formatTextValue: formatAvailability
                }
              }
            }}
            value={99.99}
            minValue={70}
            maxValue={100}
          />
        </Col>
      </Row>
      <Row className="g-3 mb-3 mt-4">
        <Col md={6}>
          {/* <GoogleMap
            initialCenter={{
              lat: 48.8583736,
              lng: 2.2922926
            }}
            mapStyle="Default"
            darkStyle="Cobalt"
            className="vh-50"
          >
            <h5 className="text-700">Eiffel Tower</h5>
            <p className="fs-10 mb-0">
              Gustave Eiffel's iconic, wrought-iron 1889 tower,
              <br /> with steps and elevators to observation decks.
            </p>
          </GoogleMap> */}
          <FalconLightBox image={DELLAincMapImg}>
            <img
              src={DELLAincMapImg}
              className="img-fluid rounded mb-2 cursor-pointer "
            />
          </FalconLightBox>
        </Col>
        <Col md={6}>
          <h4>Customer</h4>
          <ListGroup>
            <ListGroup.Item style={{ fontSize: '14px' }}>
              DELL AINC Network Map
            </ListGroup.Item>
          </ListGroup>
        </Col>
      </Row>
    </Fragment>
  );
};

export default AdminMultiClouds;
