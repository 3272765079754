import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PageHeader from 'components/common/PageHeader';
import React, { Fragment } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import GaugeChart from 'react-gauge-chart';

const Gauge = () => {
  return (
    <Fragment>
      <PageHeader
        title="Gauge Chart"
        description="Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime mollitia,
molestiae quas vel sint commodi repudiandae consequuntur voluptatum laborum
numquam blanditiis harum quisquam eius sed odit fugiat iusto fuga praesentium
optio, eaque rerum! Provident similique accusantium nemo autem."
        className="mb-3"
      >
        <Button
          href={`https://echarts.apache.org/en/option.html#series-map.type`}
          target="_blank"
          variant="link"
          size="sm"
          className="ps-0"
        >
          Explore more
          <FontAwesomeIcon icon="chevron-right" className="ms-1 fs-11" />
        </Button>
      </PageHeader>
      <Row className="g-3 mb-3 mt-2">
        <Col md={6} mt={4}>
          <h6 className="mb-0 mt-2 text-center">
            V-Lan #1 for threshold percentage
          </h6>
          <GaugeChart
            id="gauge-chart1"
            arcsLength={[0.3, 0.5, 0.2]}
            colors={['#5BE12C', '#F5CD19', '#EA4228']}
            percent={0.37}
            nrOfLevels={10}
            arcPadding={0.1}
            cornerRadius={3}
          />
        </Col>

        <Col md={6}>
          <h6 className="mb-0 mt-2 text-center">
            V-Lan #2 for threshold percentage
          </h6>
          <GaugeChart
            id="gauge-chart2"
            arcsLength={[0.4, 0.3, 0.3]}
            colors={['#5BE12C', '#F5CD19', '#EA4228']}
            percent={0.77}
            nrOfLevels={10}
            arcPadding={0.1}
            cornerRadius={3}
          />
        </Col>
      </Row>

      <Row className="g-3 mb-3">
        <Col md={6}>
          <h6 className="mb-0 mt-2 text-center">
            V-Lan #3 for threshold percentage
          </h6>
          <GaugeChart
            id="gauge-chart3"
            arcsLength={[0.3, 0.3, 0.4]}
            colors={['#5BE12C', '#F5CD19', '#EA4228']}
            percent={0.97}
            nrOfLevels={10}
            arcPadding={0.1}
            cornerRadius={3}
          />
        </Col>

        <Col md={6}>
          <h6 className="mb-0 mt-2 text-center">
            V-Lan #4 for threshold percentage
          </h6>
          <GaugeChart
            id="gauge-chart4"
            arcsLength={[0.2, 0.6, 0.2]}
            colors={['#5BE12C', '#F5CD19', '#EA4228']}
            percent={0.17}
            nrOfLevels={10}
            arcPadding={0.1}
            cornerRadius={3}
          />
        </Col>
      </Row>

      <Row className="g-3 mb-3">
        <Col md={6}>
          <h6 className="mb-0 mt-2 text-center">
            V-Lan #5 for threshold percentage
          </h6>
          <GaugeChart
            id="gauge-chart5"
            arcsLength={[0.4, 0.4, 0.2]}
            colors={['#5BE12C', '#F5CD19', '#EA4228']}
            percent={0.4}
            nrOfLevels={10}
            arcPadding={0.1}
            cornerRadius={3}
          />
        </Col>

        <Col md={6}>
          <h6 className="mb-0 mt-2 text-center">
            V-Lan #6 for threshold percentage
          </h6>
          <GaugeChart
            id="gauge-chart6"
            arcsLength={[0.2, 0.6, 0.2]}
            colors={['#5BE12C', '#F5CD19', '#EA4228']}
            percent={0.3}
            nrOfLevels={10}
            arcPadding={0.1}
            cornerRadius={3}
          />
        </Col>
      </Row>

      <Row className="g-3 mb-3">
        <Col md={6}>
          <h6 className="mb-0 mt-2 text-center">
            V-Lan #7 for threshold percentage
          </h6>
          <GaugeChart
            id="gauge-chart7"
            arcsLength={[0.4, 0.4, 0.2]}
            colors={['#5BE12C', '#F5CD19', '#EA4228']}
            percent={0.91}
            nrOfLevels={10}
            arcPadding={0.1}
            cornerRadius={3}
          />
        </Col>

        <Col md={6}>
          <h6 className="mb-0 mt-2 text-center">
            V-Lan #8 for threshold percentage
          </h6>
          <GaugeChart
            id="gauge-chart8"
            arcsLength={[0.4, 0.2, 0.4]}
            colors={['#5BE12C', '#F5CD19', '#EA4228']}
            percent={0.39}
            nrOfLevels={10}
            arcPadding={0.1}
            cornerRadius={3}
          />
        </Col>
      </Row>
    </Fragment>
  );
};

export default Gauge;
