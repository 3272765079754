import React, { Fragment } from 'react';
import Disney from './NavalNetworkMapGauge';
import Equinix from './DHSNetworkMap';

const CustomerAINC = () => {
  const user = 'disney';
  return <Fragment>{user === 'disney' ? <Disney /> : <Equinix />}</Fragment>;
};

export default CustomerAINC;
