import React from 'react';
import paths from 'routes/paths';
import { Link } from 'react-router-dom';
import useAdvanceTable from './useAdvanceTable';
import Avatar from 'components/common/Avatar';
import Flex from 'components/common/Flex';
import SubtleBadge from 'components/common/SubtleBadge';
import { tickets } from 'data/dashboard/support-desk';
import Priority from 'components/app/support-desk/tickets-layout/Priority';
// import { Button } from 'react-bootstrap';

const columns = [
  {
    accessorKey: 'name',
    header: 'Network Owner',
    meta: {
      headerProps: { className: 'ps-2 text-900', style: { height: '46px' } },
      cellProps: {
        className: 'py-2 white-space-nowrap pe-3 pe-xxl-4 ps-2'
      }
    },
    cell: ({ row: { original } }) => {
      const { name, avatar } = original;
      return (
        <Flex alignItems="center" className="position-relative py-1">
          {avatar.img ? (
            <Avatar src={avatar.img} size="xl" className="me-2" />
          ) : (
            <Avatar size="xl" name={avatar.name} className="me-2" />
          )}
          <h6 className="mb-0">
            <Link to={paths.contactDetails} className="stretched-link text-900">
              {name}
            </Link>
          </h6>
        </Flex>
      );
    }
  },
  {
    accessorKey: 'subject',
    header: 'Date & Time',
    meta: {
      headerProps: {
        style: { minWidth: '14.625rem' },
        className: 'text-900'
      },
      cellProps: {
        className: 'py-2 pe-4'
      }
    },
    cell: () => {
      // const { de } = original;
      return (
        <Link to={paths.ticketsPreview} className="fw-semibold">
          {new Date().toLocaleString()}
        </Link>
      );
    }
  },
  {
    accessorKey: 'description',
    header: 'Description',
    meta: {
      headerProps: { className: 'ps-2 text-900', style: { height: '46px' } },
      cellProps: {
        className: 'py-2 white-space-nowrap pe-3 pe-xxl-4 ps-2'
      }
    },
    cell: ({ row: { original } }) => {
      const { description } = original;
      return <p>{description}</p>;
    }
  },
  {
    accessorKey: 'status',
    header: 'Status',
    meta: {
      headerProps: { className: 'text-900' },
      cellProps: {
        className: 'fs-9 pe-4'
      }
    },
    cell: ({ row: { original } }) => {
      const { status } = original;
      return (
        <SubtleBadge bg={status.type} className="me-2">
          {status.content}
        </SubtleBadge>
      );
    }
  },
  {
    accessorKey: 'priority',
    header: 'Severity',
    meta: {
      headerProps: { className: 'text-900' },
      cellProps: {
        className: 'pe-4'
      }
    },
    cell: ({ row: { original } }) => {
      const { priority } = original;
      return (
        <Priority
          title={priority.title}
          color={priority.color}
          data={priority.data}
        />
      );
    }
  }
  // {
  //   accessorKey: 'action',
  //   header: 'Action',
  //   meta: {
  //     headerProps: { className: 'text-900' },
  //     cellProps: {
  //       className: 'pe-4'
  //     }
  //   },
  //   cell: () => {
  //     return <Button>Action</Button>;
  //   }
  // }
];

const useSupportDeskTable = options => {
  const table = useAdvanceTable({
    columns,
    data: tickets,
    ...options
  });

  return table;
};

export default useSupportDeskTable;
