import React, { Fragment, useEffect, useState } from 'react';
import { Doughnut, Line } from 'react-chartjs-2';
import { Col, Row } from 'react-bootstrap';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import axiosInstance from 'services/axiosInstance';

const lineLabels = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'DEC'
];

function formatNumber(num) {
  if (num >= 1000000000) {
    return (num / 1000000000).toFixed(2) + 'B';
  }
  if (num >= 1000000) {
    return (num / 1000000).toFixed(2) + 'M';
  }
  return num.toString();
}

const MultiCloud = () => {
  const [clouds, setClouds] = useState([]);
  const org = {
    labels: ['Equinix', 'NASA ARC', 'Mayo Clinic', 'Disney'],
    datasets: [
      {
        label: '$',
        data: [27688, 23088, 33888, 15000],
        backgroundColor: [
          '#7E4453',
          '#49841F',
          '#008080',
          '#840ECD',
          '#E97E01'
        ],
        borderColor: ['#7E4453', '#49841F', '#008080', '#840ECD', '#E97E01'],
        borderWidth: 1
      }
    ]
  };
  const [networkCost, setNetworkCost] = useState(org);
  const [costPerCSP, setCostPerCSP] = useState(null);
  const [costPerService, setCostPerService] = useState(null);
  const [lineData, setLineData] = useState({
    labels: lineLabels,
    datasets: []
  });

  useEffect(() => {
    axiosInstance.get('/customer-costs').then(res => {
      setClouds(res.data.result);
    });
  }, []);

  useEffect(() => {
    axiosInstance.get('/customer-costs/per-csp').then(res => {
      setCostPerCSP(res.data.result);
    });
  }, []);

  useEffect(() => {
    axiosInstance.get('/customer-costs/by-month/').then(res => {
      const _lineData = {
        labels: lineLabels,
        datasets: res.data.result
      };
      setLineData(_lineData);
    });
  }, []);

  useEffect(() => {
    axiosInstance.get('/network-costs').then(res => {
      setNetworkCost(res.data.network_cost);
    });
  }, []);

  useEffect(() => {
    axiosInstance.get('/customer-costs/per-service').then(res => {
      setCostPerService(res.data.result);
    });
  }, []);

  // useEffect(() => {
  //   axiosInstance.get('/cloud-cost-by-year').then(res => {
  //     setLineDataSets(res.data.datasets);
  //   });
  // }, []);

  console.log(networkCost, org);

  const options = {
    responsive: true,
    maintainAspectRatio: true,
    plugins: {
      legend: {
        display: true,
        position: 'right',
        labels: {
          boxWidth: 15,
          usePointStyle: true,
          color: '#fff',
          font: {
            family: 'poppins'
          }
        }
      },
      datalabels: {
        formatter: function (value) {
          return '$' + Math.floor(value / 1000); //for number format
          // return '$' + formatNumber(value);
        },
        color: '#fff',
        font: {
          weight: 'bold',
          size: 12,
          family: 'poppins'
        }
      }
    }

    // cutout: [12645, 16029, 14355, 15837, 2000, 3990].map(item => item)
  };

  const lineOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
        labels: {
          color: '#fff'
        }
      },
      title: {
        display: true,
        text: 'Cost Per Cloud $K',
        color: '#fff'
      }
    },
    scales: {
      x: {
        grid: {
          color: 'rgba(255, 255, 255, 0.5)' // Change color of x axis grid
        },
        ticks: {
          color: 'white' // Change color of x axis labels to white
        }
      },
      y: {
        grid: {
          color: 'rgba(255, 255, 255, 0.5)' // Change color of y axis grid
        },
        ticks: {
          color: 'white' // Change color of y axis labels to white
        }
      }
    }
  };

  return (
    <Fragment>
      <h4 style={{ marginTop: '8px' }}>DELL AINC Admin</h4>
      <Row className="g-3 mb-3 mt-4">
        {clouds?.length > 0 &&
          clouds?.map(cloud => {
            return (
              <Col md={3} xxl={3} mt={4} key={cloud.provider_name}>
                <h5 className="mb-2 mt-2 text-center">
                  {cloud.provider_name} Cost Details
                  <br /> ${formatNumber(cloud.total_cost)}
                </h5>
                <Doughnut
                  data={cloud.data}
                  plugins={[ChartDataLabels]}
                  options={options}
                />
              </Col>
            );
          })}
      </Row>
      <Row>
        <Col md={6}>
          <Line options={lineOptions} data={lineData} />
        </Col>
        {costPerCSP !== null && (
          <Col md={3} xxl={3} mt={4}>
            <h5 className="mt-2 text-center">
              Cost Per CSP <br />$
              {
                // +(costPerCSP.totalSum / 1000).toFixed(
                //   +costPerCSP.totalSum > 10000 ? 1 : 2
                // )
                formatNumber(costPerCSP.totalSum)
              }
            </h5>
            <Doughnut
              data={costPerCSP}
              plugins={[ChartDataLabels]}
              options={options}
            />
          </Col>
        )}
        {costPerService !== null && (
          <Col md={3} xxl={3} mt={4}>
            <h5 className="mt-2 text-center">
              Cost Per Service <br />$
              {
                // +(costPerService.totalSum / 1000).toFixed(
                //   +costPerService.totalSum > 10000 ? 1 : 2
                // )
                formatNumber(costPerService.totalSum)
              }
            </h5>
            <Doughnut
              data={costPerService}
              plugins={[ChartDataLabels]}
              options={options}
            />
          </Col>
        )}
      </Row>
    </Fragment>
  );
};

export default MultiCloud;
