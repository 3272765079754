import React from 'react';
import { useAppContext } from 'providers/AppProvider';
// import GoogleMap from 'components/map/GoogleMap';

const MapDark = () => {
  // return (
  //   // <GoogleMap
  //   //   initialCenter={{
  //   //     lat: 23.8383608,
  //   //     lng: 90.3680554
  //   //   }}
  //   //   mapStyle="Cobalt"
  //   //   className="vh-50 rounded-soft mt-5"
  //   // />
  // );
  return null;
};
const MapLight = () => {
  // return (
  //   <GoogleMap
  //     initialCenter={{
  //       lat: 23.8383608,
  //       lng: 90.3680554
  //     }}
  //     mapStyle="Default"
  //     className="vh-50 rounded-soft mt-5"
  //   ></GoogleMap>
  // );
  return null;
};
const EventDetailsGmap = () => {
  const {
    config: { isDark }
  } = useAppContext();

  return <>{isDark ? <MapDark /> : <MapLight />}</>;
};

export default EventDetailsGmap;
