import React, { Fragment, useEffect, useState } from 'react';
import { Doughnut, Line } from 'react-chartjs-2';
import { Col, Row } from 'react-bootstrap';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import axiosInstance from 'services/axiosInstance';
import NavalNetworkMapGauge from '../customer-multi-clouds/NavalNetworkMapGauge';
const lineLabels = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'DEC'
];

function formatNumber(num) {
  if (num >= 1000000000) {
    return (num / 1000000000).toFixed(2) + 'B';
  }
  if (num >= 1000000) {
    return (num / 1000000).toFixed(2) + 'M';
  }
  return num.toString();
}
const NavalNetworkMap = () => {
  const [, setClouds] = useState([]);
  const [customerData, setCustomerData] = useState([]);
  const [lineData, setLineData] = useState({
    labels: lineLabels,
    datasets: []
  });

  const [, setLineDataSets] = useState([]);
  const [costPerCSP, setCostPerCSP] = useState(null);
  const [costPerService, setCostPerService] = useState(null);

  useEffect(() => {
    axiosInstance.get('/cloud-costs').then(res => {
      setClouds(res.data.cloudsWithCosts);
    });
  }, []);

  useEffect(() => {
    // setLineDataStatus('fetching');
    axiosInstance.get('/customer-costs/DOD Network/by-month/').then(res => {
      const _lineData = {
        labels: lineLabels,
        datasets: res.data.result
      };
      setLineData(_lineData);
    });
    // .finally(() => {
    //   setLineDataStatus('idle');
    // });
  }, []);

  useEffect(() => {
    axiosInstance.get('/customer-costs/DOD Network/per-csp').then(res => {
      setCostPerCSP(res.data.result);
    });
  }, []);

  useEffect(() => {
    axiosInstance.get('/customer-costs/DOD Network').then(res => {
      setCustomerData(res.data.result);
    });
  }, []);

  useEffect(() => {
    axiosInstance.get('/cloud-cost-by-year').then(res => {
      setLineDataSets(res.data.datasets);
    });
  }, []);

  useEffect(() => {
    axiosInstance.get('/customer-costs/DOD Network/per-service').then(res => {
      setCostPerService(res.data.result);
    });
  }, []);

  const options = {
    responsive: true,
    maintainAspectRatio: true,
    plugins: {
      legend: {
        display: true,
        position: 'right',
        labels: {
          boxWidth: 15,
          usePointStyle: true,
          color: '#fff',
          font: {
            family: 'poppins'
          }
        }
      },
      datalabels: {
        formatter: function (value) {
          return '$' + Math.floor(+value / 1000); //for number format
        },
        color: '#fff',
        font: {
          weight: 'bold',
          size: 12,
          family: 'poppins'
        }
      }
    }

    // cutout: [12645, 16029, 14355, 15837, 2000, 3990].map(item => item)
  };

  const lineOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
        labels: {
          color: '#fff'
        }
      },
      title: {
        display: true,
        text: 'Cost Per Cloud $K',
        color: '#fff'
      }
    },
    scales: {
      x: {
        grid: {
          color: 'rgba(255, 255, 255, 0.5)' // Change color of x axis grid
        },
        ticks: {
          color: 'white' // Change color of x axis labels to white
        }
      },
      y: {
        grid: {
          color: 'rgba(255, 255, 255, 0.5)' // Change color of y axis grid
        },
        ticks: {
          color: 'white' // Change color of y axis labels to white
        }
      }
    }
  };

  return (
    <Fragment>
      <h4 style={{ marginTop: '8px' }}>DOD Network</h4>
      <Row className="g-3 mb-3 mt-4">
        {customerData.map(customer => {
          return (
            <Col md={3} xxl={3} mt={4} key={customer.provider_name}>
              <h5 className="mb-2 mt-2 text-center">
                {customer.provider_name} Cost Details <br />$
                {
                  +(customer.total_cost / 1000).toFixed(
                    +customer.total_cost > 10000 ? 1 : 2
                  )
                }
                K
              </h5>
              <Doughnut
                data={customer.data}
                plugins={[ChartDataLabels]}
                options={options}
              />
            </Col>
          );
        })}
      </Row>
      <Row>
        <Col md={6}>
          <Line options={lineOptions} data={lineData} />
        </Col>
        {costPerCSP !== null && (
          <Col md={3} xxl={3} mt={4}>
            <h5 className="mt-2 text-center">
              Cost Per CSP <br />${formatNumber(costPerCSP.totalSum)}
            </h5>
            <Doughnut
              data={costPerCSP}
              plugins={[ChartDataLabels]}
              options={options}
            />
          </Col>
        )}
        {costPerService !== null && (
          <Col md={3} xxl={3} mt={4}>
            <h5 className="mt-2 text-center">
              Cost Per Service <br />${formatNumber(costPerService.totalSum)}
            </h5>
            <Doughnut
              data={costPerService}
              plugins={[ChartDataLabels]}
              options={options}
            />
          </Col>
        )}
      </Row>
      <NavalNetworkMapGauge />
    </Fragment>
  );
};

export default NavalNetworkMap;
